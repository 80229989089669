import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  .MuiDataGrid-row:focus {
    outline: none; /* Remove a borda de foco */
  }

  .green {
    background-color: #b3dab6;
    border-left: 1px solid #31af39;
    border-right: 1px solid #31af39;
  }

  .form-control {
    font-size: 14px;
  }
  .form-control:disabled,
  .form-control[readonly] {
    font-size: 14px;
    color: #a5adba;
    background-color: #e9ecef;
    border: 0;
    cursor: not-allowed;
  }

  .form-control.is-invalid {
    background-image: none;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    border-bottom: 2px solid #cf1919;
    &:focus {
      box-shadow: none;
    }
  }
  input {
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    background-color: #fff;
    font-size: 0.875rem;
    padding: 8px 6px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    &:focus {
      border-color: var(--ds-border-focus, #4c9aff);
      outline: none;
      box-shadow: none;
    }
  }

  @media screen and (max-width: 767px) {
    .containerChild {
      .cancel {
        min-width: 130px;
      }
      .imprimir {
        min-width: 130px;
      }
      .confirm {
        min-width: 130px;
      }
    }
  }
`;

export const ContainerButtCapa = styled.div`
  display: flex;
  flex-direction: columns;
  margin-top: 10px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 5px;

    
    @media screen and (max-width: 991px) {
      margin-left: 0px;
      justify-content: flex-start;
    }

    .colButton {
      min-width: 170px;
      max-width: 170px;
      height: 42px;

      @media screen and (max-width: 991px) {
        min-width: 33.3% !important;
      display: flex;
      justify-content: flex-start;
      margin-left: 0px;
      }
    }

    .colButton2 {
      min-width: 170px;
      max-width: 170px;
      height: 42px;

      @media screen and (max-width: 991px) {
        min-width: 33.3% !important;

      }
    }
  }



  @media screen and (max-width: 767px) {
    .row {
      flex-direction: row;
      justify-content: space-between;
      margin-left: 0px;

    .colButton {
      min-width: 31%;
      max-width: 31%;
      margin-bottom: 5px;
      padding-right:0px !important;
      padding-left:0px !important;
    }
    .colButton2 {
      min-width: 31%;
      max-width: 31%;
      padding-right:0px !important;
      padding-left:0px !important;
    }
    .colButton .confirm {
      min-width: 100% !important;
    }
    .colButton .clear {
      min-width: 100% !important;
    }
    .colButton2 .confirm {
      min-width: 100% !important;
    }
  }
`;

export const Title = styled.h3`
  margin-top: 20px;
  margin-bottom: 0px;
  color: #313638;
  font-size: calc(1.3rem + 0.6vw);
  line-height: 40px;
`;

export const SeparatorLine = styled.p`
  margin-top: 40px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ededed;
  height: 5px;
  width: 99.95%;
`;

export const Button = styled.button`
  padding: 3px 12px;
  height: 40px;
  min-width: 150px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.search {
    background-color: #8850bf;
    &:disabled {
      background-color: #b192d1;
      cursor: not-allowed;
      &:hover {
        background-color: #b192d1;
        opacity: 1;
      }
    }

    @media screen and (max-width: 767px) {
      min-width: 130px !important;
    }
    @media screen and (max-width: 991px) {
      width: 170px !important;
    }
  }
  &.confirm {
    background-color: #007bff;
    &:disabled {
      background-color: #6db3ff;
      cursor: not-allowed;
      &:hover {
        background-color: #6db3ff;
        opacity: 1;
      }
    }
    @media screen and (max-width: 991px) {
      width: 170px !important;
    }
  }
  &.imprimir {
    background-color: #808080;
    &:disabled {
      background-color: #aaaaaa;
      cursor: not-allowed;
      &:hover {
        background-color: #aaaaaa;
        opacity: 1;
      }
    }
  }

  &:nth-child(2) {
    margin-left: 8px;
  }
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: flex-end;
`;
